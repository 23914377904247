<template>
  <div>
    <el-form ref="form" :model="form" label-width="100px" style="display: flex">
      <div>
        <el-row type="flex" justify="space-between">
          <div style="width: 85%">
            <el-row :span="24">
              <el-col :span="12">
                <el-form-item label="名称">
                  <el-input v-model="form.labelName" :placeholder="$t('page.inputPlaceholder')" />
                </el-form-item>
              </el-col>
            </el-row>
            <!-- <el-row :span="24" class="mb-3">
              <el-col :span="24" style="display:flex;height:36px;">
                <el-button type="pa" @click="handleAssign">{{ $t('title.distribute') }}</el-button>
              </el-col>
            </el-row> -->
          </div>
          <el-row style="width: 15%">
            <el-col class="row-center">
              <el-button @click="queryClick()">{{ $t('page.search') }}</el-button>
              <el-button type="primary" @click="addTag(false)">新增</el-button>
              <el-button type="primary" @click="dialogImportVisible = true">导入</el-button>
            </el-col>
          </el-row>
        </el-row>
      </div>
    </el-form>
    <el-table
      ref="multipleTable"
      v-loading="TableLoading"
      row-key="id"
      :tree-props="{children: 'childNodes', hasChildren: 'hasChildren'}"
      :data="tableData"
      :header-cell-style="{background:'#fafafa',color:'#606266'}"
      max-height="500px"
    >
      <el-table-column
        prop="labelName"
        label="标签名称"
        align="center"
        sortable
      />

      <el-table-column
        prop="labelNameEN"
        label="英文名称"
        align="center"
        sortable
      />
      <el-table-column
        prop="status"
        align="center"
        :label="$t('page.status')"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.delFlag === 0? '启用':'禁用' }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="createByName"
        :label="$t('page.createByName')"
        align="center"
        sortable
      />

      <el-table-column
        prop="createByTime"
        :label="$t('page.createTime')"
        align="center"
        sortable
      />
      <el-table-column
        fixed="right"
        align="center"
        label="操作"
        width="90"
      >
        <template slot-scope="scope">
          <!-- v-if="scope.row.labelLevel == 1 || (scope.row.labelLevel == 2 && scope.row.delFlag == 0 ) || (scope.row.labelLevel == 2 && scope.row.delFlag == 1) " -->

          <el-button
            v-if="!(scope.row.labelLevel == 2 && scope.row.parentDelFlag === 1)"
            type="text"
            @click.native.prevent="clickLabels(scope.row,true)"
          >
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="pager.current"
      :page-sizes="[20, 50, 100, 200, 300]"
      :page-size="pager.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pager.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <el-dialog :title="isTrue === false?'新增':'编辑'" :close-on-click-modal="false" width="50%" :before-close="cancelLabels" :visible.sync="dialogFormVisibleLabels">
      <el-form ref="addForm" :model="addForm" label-width="100px" :rules="rules">
        <el-row :span="12">
          <el-col :span="24">
            <el-form-item label="标签等级" prop="labelLevel">
              <el-select v-model="addForm.labelLevel" :disabled="isTrue" clearable filterable style="width:100%" placeholder="请选择">
                <el-option label="一级类别" :value="1" />
                <el-option label="二级类别" :value="2" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item v-if="addForm.labelLevel == '2'" label="一级标签" prop="parentId">
              <el-select v-model="addForm.parentId" :disabled="isTrue" clearable filterable placeholder="请选择" style="width:100%" @visible-change="isVisibleChange($event)">
                <el-option
                  v-for="item in labelList"
                  :key="item.id"
                  :label="item.labelName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="标签名称" prop="labelName">
              <el-input v-model="addForm.labelName" :disabled="isTrue" maxlength="15" show-word-limit />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="英文名称" prop="labelNameEN">
              <el-input v-model="addForm.labelNameEN" :disabled="isTrue" />
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="状态" prop="delFlag">
              <el-radio-group v-model="addForm.delFlag">
                <el-radio :label="0">启用</el-radio>
                <el-radio :label="1">禁用</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelLabels">{{ $t('title.cancel') }}</el-button>
        <el-button :loading="loading" type="primary" @click="submitLabels">{{ $t('title.sure') }}</el-button>
      </div>
    </el-dialog>
    <el-dialog title="导入" :visible.sync="dialogImportVisible" width="50%">
      <p>点击此处:<span style="color:#61C6F0;cursor:pointer" @click="downLoad()">下载模板</span></p>
      <el-form ref="dischargeForm" :model="dischargeForm" label-width="100px">
        <el-form-item>
          <el-upload
            ref="upload"
            :headers="uploadHeaders"
            :before-upload="beforeAvatarUpload"
            :on-success="handleUploadSuccess"
            :on-remove="handleFileRemove"
            :on-progress="uploadFileProcess"
            :action="action"
            drag
          >
            <i class="el-icon-upload" />
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div slot="tip" class="el-upload__tip"><span style="color:red">Tip:请上传.xls 或 .xlsx的文件</span> </div>
          </el-upload>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import mixin from '@/mixin/oms-mixin'
import oms_base from '@/api/a-base-host'
import { getLabelsByPage, importLabel, messagesSaveLabel, messagesGetLabel, updateLabelsStatusById } from '@/api/service-management'
export default {
  mixins: [mixin],
  data() {
    // const inpLabelName = (rule, value, callback) => {
    //   if (!value) {
    //     return callback(new Error(this.$t('page.required')))
    //   } else {
    //     // const reg = /^[A-Za-z0-9-]{1,32}$/
    //     const reg = /^[a-zA-Z\u4e00-\u9fa5]{1,15}$/g
    //     if (reg.test(value)) {
    //       callback()
    //     } else {
    //       return callback(new Error('只允许输入中文和英文，长度15以内'))
    //     }
    //   }
    // }
    const inpLabelNameEN = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('page.required')))
      } else {
        // const reg = /^[A-Za-z0-9-]{1,32}$/
        // const reg = /^[A-Za-z][A-Za-z\s'-]*[A-Za-z']{1,50}$/g 只允许输入英文/空格 和 特殊字符，长度50以内
        const reg = /^[^\u4e00-\u9fa5]{0,50}$/g // 不允许输入中文
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('不允许输入中文，长度50以内'))
        }
      }
    }
    return {
      pager: {
        current: 1,
        size: 20,
        total: 0
      },
      rowId: '',
      uploadHeaders: {
        authorization: this.$store.getters.authorization
      },
      dialogFormVisibleLabels: false,
      TableLoading: false,
      tableData: [],
      form: {
        labelName: ''
      },
      loading: false,
      labelList: [],
      addForm: {
        labelName: '',
        labelLevel: '',
        labelNameEN: '',
        parentId: '',
        delFlag: ''
      },
      action: process.env.VUE_APP_UPLOAD_API,
      dialogFormVisibleAssign: false,
      labelLoading: false,
      isTrue: false,
      dischargeForm: {
        fileUrl: ''
      },
      dialogImportVisible: false,
      rules: {
        labelName: [{ required: true, message: this.$t('page.required'), trigger: ['blur', 'change'] }],
        parentId: [{ required: true, message: this.$t('page.required'), trigger: ['blur', 'change'] }],
        labelLevel: [{ required: true, message: this.$t('page.required'), trigger: ['blur', 'change'] }],
        labelNameEN: [{ required: true, validator: inpLabelNameEN, trigger: ['blur', 'change'] }],
        delFlag: [{ required: true, message: this.$t('page.required'), trigger: ['blur', 'change'] }]

      }

    }
  },

  computed: {
    queryParameter() {
      return Object.assign({}, this.form, this.pager)
    },
    exportUrl() {
      const [startTime = '', endTime = ''] = this.form.date || []
      const { userName } = this.form
      return oms_base + `/oms/review/exportReviewInfos?startTime=${startTime}&endTime=${endTime}&userName=${userName}`
    }
  },

  mounted() {
    this._getLabelsByPage()
  },
  methods: {

    clickLabels(row, isDisAbel) {
      console.log(row)
      this.isTrue = isDisAbel
      const { labelLevel, labelName, labelNameEN, delFlag, parentId, id } = row
      this.rowId = id
      this.addForm = { labelLevel, labelName, labelNameEN, delFlag, parentId }
      this.dialogFormVisibleLabels = true
    },
    addTag(isDisAbel) {
      this.isTrue = isDisAbel
      this.dialogFormVisibleLabels = true
    },
    // 取消关闭输入框
    cancelLabels() {
      this.dialogFormVisibleLabels = false
      Object.assign(this.addForm, this.$options.data.call(this).addForm)
    },
    async isVisibleChange(flag) {
      if (flag) {
        try {
          this.loading = true
          const { datas } = await messagesGetLabel(false)
          this.labelList = datas
          this.loading = false
        } finally {
          this.loading = false
        }
      }
    },
    // 点击查询获取信息
    queryClick() {
      this.pager.current = 1
      this._getLabelsByPage(this.queryParameter)
    },
    // 点击重置清空文本框信息
    resetClick() {
      this.form = {}
      this._getLabelsByPage(1)
    },
    // 默认查询
    async _getLabelsByPage(params) {
      try {
        this.TableLoading = true
        const { datas } = await getLabelsByPage(this.queryParameter)
        this.tableData = datas.records
        this.tableData.map(e => {
          if (e.delFlag === 1) {
            e.childNodes.map(v => {
              v.parentDelFlag = 1
            })
          }
        })
        this.pager = datas.pager
      } finally {
        this.TableLoading = false
      }
    },
    downLoad() {
      window.open('https://aliyun-oa-query-results-1509030252432744-oss-cn-hongkong.oss-cn-hongkong.aliyuncs.com/template/%E6%A0%87%E7%AD%BE%E6%A8%A1%E6%9D%BF.xlsx')
    },
    handleSizeChange(val) {
      this.pager.size = val
      this._getLabelsByPage(this.queryParameter)
    },
    handleCurrentChange(val) {
      this.pager.current = val
      this._getLabelsByPage(this.queryParameter)
    },

    submitLabels() {
      this.$refs.addForm.validate((valid) => {
        if (!valid) return false
        const { labelName, labelLevel, labelNameEN, parentId, delFlag } = this.addForm
        if (this.isTrue === true) {
          console.log(this.rowId, this.addForm.delFlag,)
          this._updateLabelsStatusById()
        } else {
          this._messagesSaveLabel({ labelName, labelLevel, labelNameEN, parentId: parentId || '-1', delFlag })
        }
      })
    },
    // 更新状态
    async _updateLabelsStatusById() {
      try {
        this.labelLoading = true
        const { code, msg } = await updateLabelsStatusById(this.rowId, this.addForm.delFlag)
        code === 0
          ? this.$message.success(msg)
          : this.$message.error(msg)
        this._getLabelsByPage(this.queryParameter)
        this.isTrue = true
      } finally {
        this.labelLoading = false
        this.dialogFormVisibleLabels = false
        this._getLabelsByPage(this.queryParameter)
        Object.assign(this.addForm, this.$options.data.call(this).addForm)
      }
    },
    // 新增
    async _messagesSaveLabel(data) {
      try {
        this.labelLoading = true
        const { code, msg } = await messagesSaveLabel(data)
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
        }
        this._getLabelsByPage(this.queryParameter)
      } finally {
        this.labelLoading = false
        this.dialogFormVisibleLabels = false
        this._getLabelsByPage(this.queryParameter)
        Object.assign(this.addForm, this.$options.data.call(this).addForm)
      }
    },
    beforeAvatarUpload(file) {
      const Csv = file.name.split('.')
      if (Csv[Csv.length - 1] === 'xls' || Csv[Csv.length - 1] === 'xlsx') {
        return true
      } else {
        this.$message({
          dangerouslyUseHTMLString: true,
          type: 'error',
          message: '文件上传失败，请检查文件格式'
        })
        return false
      }
    },
    uploadFileProcess(event, file, fileList) {
      console.log(event, file, fileList)
    },
    handleUploadSuccess(res) {
      const { datas, code } = res
      if (code === 0) {
        this.dischargeForm.fileUrl = datas
        console.log(this.dischargeForm.fileUrl)
        const importData = Object.assign({}, { fileUrl: this.dischargeForm.fileUrl })
        this._importLabel(importData)
      }
    },
    async _importLabel(data) {
      try {
        const { code, datas } = await importLabel(data)

        if (code === 0) {
          this.dialogImportVisible = false
          this.$message.success(datas)

          this.$refs.upload.clearFiles()
          this._getLabelsByPage()
        } else {
          this.dialogImportVisible = false
          this.$message.success(datas)
          this.$refs.upload.clearFiles()
          this._getLabelsByPage()
        }
      } catch (err) {
        const { error } = err
        console.log(error)
        this.$alert(err, '提示', {
          confirmButtonText: '确定'
        })
        this.$refs.upload.clearFiles()
      }
    },
    handleFileRemove() {
      this.dischargeForm.fileUrl = ''
      this.$refs.upload.clearFiles()
    }

  }
}
</script>

<style scoped lang="scss">
.mark,
.count {
  color: red;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

/deep/.el-tabs__header {
  margin: 0 !important;
}
.Row-Input {
  height: 38px;
  overflow: hidden;
}
.show-row {
  height: auto;
}
</style>
